import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-links">

          <Link to="/">Etusivulle</Link>

          <Link to="/avoimet-tyopaikat">Avoimet työpaikat</Link>
          <Link to="/avoin-hakemus">Työnhakijalle</Link>
          <Link to="/yrityksille">Yrityksille</Link>


    
          <Link to="/asiantuntijoidenrekrytointi">Palvelut</Link>
          <Link to="/meista">Tietoa meistä</Link>

          <Link to="/ota-yhteytta">Ota yhteyttä</Link>

          <Link to="/tietosuojaseloste">Tietosuojakäytäntö</Link>
        </div>
        <div className="footer-copyright">
          <p>© {new Date().getFullYear()} Kuura Agency</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
