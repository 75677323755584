import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Hakijatkaup.css';

function HakijatKaup() {
  const navigate = useNavigate();

  useEffect(() => {
    const heroBackground = document.querySelector('.hakijatkaup-hero-background');

    const createTrailDot = (event) => {
      const dot = document.createElement('div');
      dot.className = 'trail-dot';
      dot.style.left = `${event.clientX - heroBackground.offsetLeft}px`;
      dot.style.top = `${event.clientY - heroBackground.offsetTop}px`;
      heroBackground.appendChild(dot);

      setTimeout(() => {
        dot.remove();
      }, 1000);
    };

    heroBackground.addEventListener('mousemove', createTrailDot);

    return () => {
      heroBackground.removeEventListener('mousemove', createTrailDot);
    };
  }, []);

  const handleNavigate = () => {
    navigate('/yrityksille');
  };

  return (
    <div>
      {/* SEO Metadata */}
      <Helmet>
        <title>Kaupallisen Alan Rekrytointi | Kuura Agency</title>
        <meta
          name="description"
          content="Kuura Agencyn kaupallisen alan rekrytointipalvelut yhdistävät alan asiantuntemuksen ja kohdennetut menetelmät. Löydä parhaat asiantuntijat, jotka vievät liiketoimintaa eteenpäin."
        />
      </Helmet>

      {/* Hero Section */}
      <div className="hakijatkaup-hero-background">
        <div className="hakijatkaup-hero-content">
          <h1 className="hakijatkaup-hero-title">Kaupallisen alan rekrytointi</h1>
          <p className="hakijatkaup-hero-text">
            Löydä oikeat asiantuntijat yrityksesi kasvun ja tavoitteiden tueksi.
          </p>
        </div>
      </div>

      {/* Main Section */}
      <div className="hakijatkaup-section">
        <div className="hakijatkaup-content">
          <h2 className="hakijatkaup-title">Kuinka Kuura Agency tukee kaupallisen alan rekrytointia?</h2>
          <p className="hakijatkaup-paragraph">
          Kaupallisen alan rekrytoinnissa on kyse oikeiden osaajien löytämisestä, mutta myös heidän sovittamisestaan osaksi organisaation tavoitteita ja kulttuuria. Kuura Agency on kumppanisi, kun tarvitset ammattilaisia, jotka paitsi vastaavat tehtävän vaatimuksiin, myös tuovat lisäarvoa ja vahvistavat tiimiäsi. Meidän tavoitteemme on auttaa sinua löytämään osaajat, jotka tukevat liiketoimintasi kasvua ja pitkän aikavälin menestystä.
 
 Ymmärrämme, että kaupallisen alan rekrytointi vaatii tarkkuutta ja huolellista taustatyötä. Kiinnitämme erityistä huomiota substanssiosaamiseen, kulttuuriseen sopivuuteen sekä siihen, että osaajat pystyvät vastaamaan nopeasti muuttuvan markkinaympäristön vaatimuksiin.          </p>
          <button className="hakijatkaup-button" onClick={handleNavigate}>
            Etsi ehdokkaita
          </button>
        </div>
        <div className="hakijatkaup-image">
        <img
            src={require('./img/kaup1.png')} // Päivitetty tiedostopolku
            alt="Kaupallisen alan ammattilaiset työskentelevät"
          />
        </div>
      </div>

      {/* Additional Content Section */}
      <div className="additional-content">
        <div className="additional-text">
          <h2>Miksi valita Kuura Agency kaupallisen alan rekrytointiin?</h2>
          <p>
            <strong>Vankka toimialaosaaminen</strong> Kuura Agencyn tiimin kokemus kaupallisista asiantuntijatehtävistä antaa meille kyvyn tunnistaa, mitä menestys alalla edellyttää. Perustajillamme on vuosien käytännön kokemus erilaisista kaupallisista rooleista, ja tämä tausta antaa meille arvokasta tietoa siitä, miten tunnistaa ja valita oikeat ehdokkaat. Lisäksi seuraamme aktiivisesti markkinoiden ja toimialan kehitystä, mikä auttaa meitä pysymään ajan tasalla asiakkaidemme tarpeista.</p>
          <p>
            <strong>Tarkasti suunniteltu hakuprosessi</strong> Rekrytointiprosessimme lähtee liikkeelle perusteellisesta tarvekartoituksesta, jossa määrittelemme roolin ja yrityksen erityisvaatimukset. Vaikka olemme kasvuyritys, hyödynnämme perustajiemme kokemusta ja moderneja menetelmiä, jotka mahdollistavat oikeiden osaajien löytämisen. Pyrimme tarjoamaan joustavan ja henkilökohtaisen palvelun, joka vastaa sekä yritysten että ehdokkaiden odotuksia.</p>
          <p>
            <strong>Kestävä yhteistyö ja tuki</strong> Rekrytointi on pitkäjänteistä työtä, eikä prosessi pääty siihen, kun uusi osaaja aloittaa tehtävässään. Kuura Agency sitoutuu tukemaan asiakkaitaan koko rekrytointipolun ajan ja rakentamaan pitkäaikaisia kumppanuuksia. Haluamme varmistaa, että valitut osaajat sopeutuvat organisaatioosi ja tukevat sen tavoitteita nyt ja tulevaisuudessa.</p>
            <p>
            <strong>Miksi valita Kuura Agency kaupallisen alan rekrytointiin?</strong> Kuura Agency tuo yhteen perustajiensa monipuolisen kokemuksen ja modernit rekrytointimenetelmät, jotka keskittyvät yrityksesi tarpeisiin. Panostamme läpinäkyvään ja avoimeen yhteistyöhön, jotta rekrytointiprosessi olisi sujuva ja tavoitteellinen. Haluamme olla kumppani, joka auttaa sinua rakentamaan liiketoimintasi tulevaisuutta.</p>
       
        </div>
      </div>
    </div>
  );
}

export default HakijatKaup;
