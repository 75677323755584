import React, { useState, useEffect } from 'react';
import './CookieConsent.css';

function CookieConsent() {
  const [isVisible, setIsVisible] = useState(false);
  const [settings, setSettings] = useState({
    necessary: true,
    analytics: false,
    functionality: false,
    marketing: false,
  });

  // Tarkista localStorage turvallisesti komponentin alkuvaiheessa
  useEffect(() => {
    try {
      const cookiesAccepted = localStorage.getItem('cookiesAccepted');
      if (!cookiesAccepted) {
        setIsVisible(true);
      }
    } catch (e) {
      console.error('LocalStorage is not available:', e);
    }
  }, []);

  const handleAcceptAll = async () => {
    try {
      localStorage.setItem('cookiesAccepted', 'true');
      localStorage.setItem(
        'cookieSettings',
        JSON.stringify({
          necessary: true,
          analytics: true,
          functionality: true,
          marketing: true,
        })
      );
      const success = await saveCookieSettings({
        analytics: true,
        functionality: true,
        marketing: true,
      });

      if (success) {
        setIsVisible(false);
      } else {
        console.error('Failed to save cookie settings after multiple attempts.');
      }
    } catch (e) {
      console.error('Failed to save to localStorage:', e);
    }
  };

  const handleAcceptNecessary = async () => {
    try {
      localStorage.setItem('cookiesAccepted', 'true');
      localStorage.setItem(
        'cookieSettings',
        JSON.stringify({
          necessary: true,
          analytics: false,
          functionality: false,
          marketing: false,
        })
      );
      const success = await saveCookieSettings({
        analytics: false,
        functionality: false,
        marketing: false,
      });

      if (success) {
        setIsVisible(false);
      } else {
        console.error('Failed to save cookie settings after multiple attempts.');
      }
    } catch (e) {
      console.error('Failed to save to localStorage:', e);
    }
  };

  const handleToggle = (type) => {
    setSettings((prev) => ({
      ...prev,
      [type]: !prev[type],
    }));
  };

  // Lähetä evästeasetukset backendille retry-logiikalla
  const saveCookieSettings = async (settings) => {
    const maxRetries = 5; // Maksimiyritysten määrä
    let attempt = 0; // Yritysten laskuri

    const userId = 'unique-user-id'; // Luo ainutlaatuinen käyttäjätunniste tarvittaessa (esim. käyttäjän ID tai sessio)

    const sendRequest = async () => {
      try {
        const response = await fetch('/save-cookie-settings', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            userId,
            ...settings,
          }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        console.log('Cookie settings saved successfully!');
        return true; // Pyyntö onnistui, ei tarvita lisäyrityksiä
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error.message);

        if (attempt < maxRetries) {
          attempt++;
          const retryDelay = Math.pow(2, attempt) * 1000; // Eksponentiaalinen viive (2^attempt sekunteina)
          console.log(`Retrying in ${retryDelay / 1000} seconds...`);

          await new Promise((resolve) => setTimeout(resolve, retryDelay)); // Odota ennen uudelleenyrittämistä
          return sendRequest(); // Yritä uudelleen
        } else {
          console.error('All retry attempts failed.');
          return false; // Palauta virhe, jos kaikki yritykset epäonnistuvat
        }
      }
    };

    return sendRequest(); // Käynnistä ensimmäinen pyyntö
  };

  if (!isVisible) return null;

  return (
    <div className="cookie-modal">
      <div className="cookie-content">
        <h2>Evästeiden käyttö</h2>
        <p>
          Tämä sivusto käyttää evästeitä parantaakseen käyttökokemustasi ja analysoidakseen
          liikennettä. Valitse alta evästeasetukset.
        </p>
        <div className="cookie-toggles">
          <div>
            <label>Välttämättömät</label>
            <input type="checkbox" checked disabled />
          </div>
          <div>
            <label>Suorituskyky ja analytiikka</label>
            <input
              type="checkbox"
              checked={settings.analytics}
              onChange={() => handleToggle('analytics')}
            />
          </div>
          <div>
            <label>Toiminnalliset</label>
            <input
              type="checkbox"
              checked={settings.functionality}
              onChange={() => handleToggle('functionality')}
            />
          </div>
          <div>
            <label>Markkinointi</label>
            <input
              type="checkbox"
              checked={settings.marketing}
              onChange={() => handleToggle('marketing')}
            />
          </div>
        </div>
        <div className="cookie-buttons">
          <button className="necessary" onClick={handleAcceptNecessary}>
            Hyväksy vain välttämättömät
          </button>
          <button className="accept-all" onClick={handleAcceptAll}>
            Hyväksy kaikki
          </button>
        </div>
        <p className="cookie-links">
          Lue lisää <a href="/tietosuojaseloste">evästekäytännöistämme</a>.
        </p>
      </div>
    </div>
  );
}

export default CookieConsent;
