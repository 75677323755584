import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

function Header() {
  const [isOpen, setIsOpen] = useState(false); // Toggle for main menu
  const [activeDropdown, setActiveDropdown] = useState(null); // Track open dropdown

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    setActiveDropdown(null); // Close any open dropdown when menu toggles
  };

  const toggleDropdown = (name) => {
    setActiveDropdown(activeDropdown === name ? null : name);
  };

  const closeMenu = () => {
    setIsOpen(false);
    setActiveDropdown(null);
  };

  return (
    <>
      <header className="header">
        <div className="logo">
          <Link to="/" onClick={closeMenu}>Kuura Agency</Link>
        </div>
        <div className={`hamburger-menu ${isOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div></div>
          <div></div>
          <div></div>
        </div>



        <nav className={`nav-links ${isOpen ? 'active' : ''}`}>
        <Link to="/" onClick={closeMenu}>Etusivulle</Link>
          <Link to="/avoimet-tyopaikat" onClick={closeMenu}>Avoimet työpaikat</Link>

          <div className="dropdown">
            <span className="dropdown-toggle" onClick={() => toggleDropdown('Työnhakijalle')}>Työnhakijalle</span>
            <div className={`dropdown-content ${activeDropdown === 'Työnhakijalle' ? 'active' : ''}`}>
              <Link to="/avoin-hakemus" onClick={closeMenu}>Lataa CV</Link>
            </div>
          </div>

          <Link to="/yrityksille" onClick={closeMenu}>Yrityksille</Link>

          <div className="dropdown">
            <span className="dropdown-toggle" onClick={() => toggleDropdown('Rekrytointi')}>Rekrytointi</span>
            <div className={`dropdown-content ${activeDropdown === 'Rekrytointi' ? 'active' : ''}`}>
              <Link to="/asiantuntijoidenrekrytointi" onClick={closeMenu}>Asiantuntijoiden Rekrytointi</Link>
              <Link to="/it-alan-rekrytointi" onClick={closeMenu}>IT-Rekrytointi</Link>
              <Link to="/kaupallisen-alan-rekrytointi" onClick={closeMenu}>Kaupallinen-Rekrytointi</Link>

              <Link to="/hybridihaku" onClick={closeMenu}>Mitä on Hybridihaku?</Link>

            </div>
          </div>

          <div className="dropdown">
            <span className="dropdown-toggle" onClick={() => toggleDropdown('Meistä')}>Meistä</span>
            <div className={`dropdown-content ${activeDropdown === 'Meistä' ? 'active' : ''}`}>
              <Link to="/meista" onClick={closeMenu}>Miksi Kuura?</Link>
            </div>
          </div>

          <div className="dropdown">
            <span className="dropdown-toggle" onClick={() => toggleDropdown('Yhteystiedot')}>Muuta</span>
            <div className={`dropdown-content ${activeDropdown === 'Yhteystiedot' ? 'active' : ''}`}>
              <Link to="/tietosuojaseloste" onClick={closeMenu}>Tietosuoja</Link>
              <Link to="/evasteet" onClick={closeMenu}>Evästeet</Link>

            </div>
          </div>
        </nav>

        <div className="contact-button">
          <Link to="/yhteytta" className="contact-link" onClick={closeMenu}>Ota yhteyttä</Link>
        </div>
      </header>

      {/* Overlay for mobile to close the menu */}
      {isOpen && <div className="overlay active" onClick={closeMenu}></div>}
    </>
  );
}

export default Header;
